<template>
  <div class="feat-car-placeholder">
    <ul class="card">
      <li v-for="(item, index) in 3" :key="index">
        <div
          class="feat-slide loading"
          :class="
            index === 0
              ? addRightBorder
              : index === 1
              ? addBorder
              : index === 2
              ? addLeftBorder
              : ''
          "
        ></div>
      </li>
    </ul>
    <div class="mobile-card"></div>
    <div class="feat-nav-container" id="feat-nav-container">
      <button class="nav-button" type="button" v-for="(dummyImage, key) in 4" :key="key"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselPlaceholder",
  data() {
    return {
      addRightBorder: "add-right-border",
      addBorder: "addBorder",
      addLeftBorder: "add-left-border"
    };
  }
};
</script>

<style lang="scss">
@import "@/sass/_components.scss";
.add-right-border {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.add-left-border {
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.addBorder {
  border-radius: 10px;
}
.feat-car-placeholder {
  padding: 10px 0;
  overflow-x: hidden;
  .card {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 40px;
    li {
      .feat-slide {
        height: 370px;
        background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
        position: relative;
        overflow: hidden;
      }
      &:nth-child(1) {
        grid-column: 1 / 3;
      }
      &:nth-child(2) {
        grid-column: 3/ 11;
      }
      &:nth-child(3) {
        grid-column: 11 / 13;
      }
    }
  }
  .mobile-card {
    background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
    height: 219px;
    padding: 0.6rem;
    border-radius: 10px;
    display: none;
  }
  .feat-nav-container {
    text-align: center;
    padding: 10px 0;

    .nav-button {
      border: 2px solid #c0c0c0;
      width: 11px;
      height: 11px;
      border-radius: 15px;
      margin-right: 15px;
      cursor: pointer;
      &.tns-nav-active {
        background: $btn-clr-new;
      }
      &:focus {
        outline: none;
      }
    }
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .feat-car-placeholder {
    padding: 10px;
    .card {
      display: none;
    }
    .mobile-card {
      display: block;
    }
  }
}
</style>
